.title {
  line-height: 0.55rem;
  display: flex;
  align-items: center;
  border-bottom: 0.01rem solid #d5d5d5;
}

.title h2 {
  display: flex;
  align-items: center;
  font-size: 0.18rem;
  color: #333333;
  font-weight: bold;
}

.title .svg-icon {
  margin-right: 0.1rem;
}